import React from 'react'
import './intro.styles.scss'
import Bounce from 'react-reveal/Bounce';
export const Intro = () => (
    <Bounce>
    <div className='intro'>
    <h1>
        Hi !! <br/><strong>I'm Sumit</strong>
    </h1>
    </div>
    </Bounce>
)